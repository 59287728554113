(function(w, d, $) {

	class FixedHeader {

		#element;

		init() {
			const header = $('header');
			this.#element = header.clone();
			this.#element.addClass('is-fixed');
			header.after(this.#element);
		}

		update() {
			const html = $('html');
			const body = $('body');
			const viewport = w.innerHeight;
			const scrollTop = html.scrollTop();
			if (scrollTop >= (viewport * 0.4) && !body.hasClass('has-fixed-header')) {
				body.addClass('has-fixed-header');
			} else if (scrollTop < (viewport * 0.4) && body.hasClass('has-fixed-header')) {
				body.removeClass('has-fixed-header');
			}
			w.requestAnimationFrame(() => {
				this.update();
			});
		}
	}

	class Faqs {

		#element;

		init() {
			this.#element = $('.faqs');
			this.#element.on('click', '.faq-question', (e) => {
				const el = $(e.currentTarget),
					faq = el.closest('.faq');
				faq.toggleClass('is-open');
				e.preventDefault();
			});
		}
	}

	class Site {

		#fixedHeader;

		#faqs;

		constructor(d) {
			jQuery(d).ready($ => {
				this.onReady($);
			});
		}

		onReady($) {
			this.#fixedHeader = new FixedHeader();
			this.#fixedHeader.init();
			this.#fixedHeader.update();

			this.#faqs = new Faqs();
			this.#faqs.init();

			$('a[href^="#"]').on('click', function(event) {
				const target = $(this.getAttribute('href'));
				if (target.length) {
					event.preventDefault();
					$('html, body').stop().animate({
						scrollTop: target.offset().top - 55
					}, 500);
				}
			});

			$('video').each((index, el) => {
				let iterations = 0;
				$(el).on('timeupdate', (e) => {
					const video = e.currentTarget;
					if(video.currentTime >= 13) {
						if (iterations < 2) {
							video.currentTime = 0;
							iterations++;
						} else {
							video.currentTime = 12;
							video.pause();
						}
						console.log(iterations);
					}
				});
			});
		}
	}

	w.site = new Site(d);

})(window, document, jQuery);